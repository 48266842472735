import {useTranslation} from "react-i18next";
import {ChangeEvent, useState} from "react";
import DataList from "../../../Components/DataList/DataList";
import ModalDataList from "../../../Components/ModalDatalist/ModalDatalist";
import {ApplicativeAccountsProps, ServerProps} from "../../../Tools/Interfaces";
import {useNavigate} from "react-router-dom";
import {Dialog} from "primereact/dialog";
import Icon from "../../../Components/Icon/Icon";
import DeleteApplicativesRights from "./DeleteApplicativesAccount";
import Input from "../../../Components/Input/Input";
import {APP_PATH} from "../../../Tools/Constants";
import {isEmailIsValidFormat, isNewAccountAlreadyExist} from "../../../Tools/Utilities";
import Button from "../../../Components/Button/Button";
import {useUserContext} from "../../../Contexts/UserContext";


const ApplicativeAccounts = () => {
    const navigate = useNavigate();
    const {t} = useTranslation();
    const [deleteVisible, setDeleteVisible] = useState<boolean>(false);
    const [accountToDelete, setAccountToDelete] = useState<string>("");
    const [accountToAdd, setAccountToAdd] = useState<string>("");
    const {applicativeAccounts, serversWithBackOfficeUserRight, setApplicativeAccounts} = useUserContext();


    const columnsApplicativeAccounts = [
        {
            id: "1",
            title: t("general.UsersTable.login"),
            body: (rowData: ApplicativeAccountsProps) => {
                return <div>{rowData.login}</div>
            }
        },
        {
            id: "2",
            title: t("general.UsersTable.accessBO"),
            body: (rowData: ApplicativeAccountsProps) => {
                const serversListWithBoAccess: ServerProps[] | undefined = rowData?.applicativeRights?.accountServersWithBoAccess?.map((server: {
                    serverId?: string
                }) => {
                    return serversWithBackOfficeUserRight.find((serverList: ServerProps) => serverList.uuid === server.serverId) as ServerProps
                });
                return <ModalDataList rowDataValue={serversListWithBoAccess}
                                      rights={rowData.boRights}
                                      columnField={"code"}
                                      headerTitle={t('general.ServersTable.modalTitle')}/>
            }
        },
        {
            id: "3",
            title: t("general.UsersTable.access_ServerBMS"),
            body: (rowData: ApplicativeAccountsProps) => {
                const serversListWithBmsAccess: ServerProps[] | undefined = rowData?.applicativeRights?.accountServersWithBmsAccess?.map(server => {
                    return serversWithBackOfficeUserRight.find(serverInArray => serverInArray.uuid === server.serverId) as ServerProps;
                });
                return <ModalDataList rowDataValue={serversListWithBmsAccess}
                                      rights={rowData.bmsRights}
                                      columnField={"code"}
                                      headerTitle={t('general.ServersTable.modalTitle')}/>
            }
        },
        {
            id: "4",
            title: t("general.UsersTable.actions"),
            body: (rowData: ApplicativeAccountsProps) => {
                return (
                    <div className="actions-button">
                        <button
                            onClick={() => {
                                navigate(APP_PATH.APPLICATIVE_ACCOUNTS_MANAGEMENT.replace(":login", rowData.login))
                            }}>{t("general.Button.modify")}
                        </button>
                        <Icon icon="bin" color='red' onClick={() => {
                            setDeleteVisible(true);
                            setAccountToDelete(rowData.login)
                        }}/>
                    </div>
                )
            }
        },
    ];

    const isNewAccountAlreadyExistInList = isNewAccountAlreadyExist(applicativeAccounts, accountToAdd)
    const renderHeader = () => (
        <div className="header-add-account">
            <Input className="input-add-account" placeholder={t('general.Input.email')}
                   value={accountToAdd}
                   onChange={(e: ChangeEvent<HTMLInputElement>) => {
                       setAccountToAdd(e.target.value.toLowerCase())
                   }}
            />
            <div className="modal-addAccount">
                <Button className="add-button"
                        disabled={!isEmailIsValidFormat(accountToAdd) || isNewAccountAlreadyExistInList}
                        onClick={() => navigate(APP_PATH.APPLICATIVE_ACCOUNTS_MANAGEMENT.replace(":login", accountToAdd))}
                        label={t('general.Button.add')}/>
            </div>
        </div>
    )

    const handleCloseDeleteRights = () => {
        setAccountToDelete("");
        setDeleteVisible(false);
    }

    return (
        <div>
            <DataList columns={columnsApplicativeAccounts} data={applicativeAccounts} renderHeader={renderHeader}/>
            <Dialog visible={deleteVisible}
                    header={t("general.AccountManagement.titleModalDelete") + accountToDelete}
                    onHide={() => setDeleteVisible(false)} style={{width: '50%'}} dismissableMask={true}>
                <DeleteApplicativesRights accountToDelete={accountToDelete}
                                          handleCloseDeleteRights={handleCloseDeleteRights}
                                          setApplicativeAccounts={setApplicativeAccounts}
                                          applicativeAccounts={applicativeAccounts}
                />
            </Dialog>
        </div>
    )
}

export default ApplicativeAccounts;