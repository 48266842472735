import {DataTableValue} from "primereact/datatable";

export const isEmailIsValidFormat = (email: string) => {
    if (email === "") return false
    return /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/g.test(email)
}


export const isListEmpty = (list: DataTableValue[]) => {
    return list.length === 0
}

export const isNewAccountAlreadyExist = (list: DataTableValue[], account: string) => {
    return list.some((item) => item.login === account)
}

export const updateApplicativesRightsOnAccount = (account, t) => {
    const boUserOnAllServers = account?.applicativeRights?.boUserOnAllServers;
    const boUserOnNoneServers = account?.applicativeRights?.boUserOnNoneServers;
    const bmsUserOnAllServers = account?.applicativeRights?.bmsUserOnAllServers;
    const bmsUserOnNoneServers = account?.applicativeRights?.bmsUserOnNoneServers;
    account.boRights = boUserOnNoneServers ? t('general.UsersTable.noneServer') : boUserOnAllServers ? t('general.UsersTable.allServer') : account?.applicativeRights?.accountServersWithBoAccess?.length;
    account.bmsRights = bmsUserOnNoneServers ? t('general.UsersTable.noneServer') : bmsUserOnAllServers ? t('general.UsersTable.allServer') : account?.applicativeRights?.accountServersWithBmsAccess?.length;
    return account
}

export const boRolesArray = [
    {role: 0, label: "Admin"},
    {role: 1, label: "Linguist"},
    {role: 2, label: "None"},
    {role: 3, label: "All"},
];