import {Dialog} from "primereact/dialog";
import {DataTable, DataTableValue} from "primereact/datatable";
import {Column} from "primereact/column";
import {useState} from "react";
import {ServerProps} from "../../Tools/Interfaces";

interface ModalDatalistProps {
    rowDataValue: DataTableValue[] | ServerProps[] | undefined,
    rights: string | number | undefined,
    header?: string,
    columnField?: string
    headerTitle?: string
}

const ModalDatalist = ({rowDataValue, rights, header, columnField, headerTitle}: ModalDatalistProps) => {
    const [visible, setVisible] = useState<boolean>(false);

    return (
        <div>
            <button className="droits"
                    onClick={() => setVisible(true)}>{rights}</button>
            <Dialog visible={visible} onHide={() => setVisible(false)} header={header} dismissableMask={true}>
                <DataTable value={rowDataValue} rows={3}>
                    <Column field={columnField} header={headerTitle}
                            style={{fontSize: "14px"}}/>
                </DataTable>
            </Dialog>
        </div>
    )
}

export default ModalDatalist