import {Column, DataTable as PrimeDataTable} from './Style';
import {DataTableValue} from "primereact/datatable";
import {
    ApplicativeAccountsProps,
    BackOfficeAccountProps,
    ServerProps
} from "../../Tools/Interfaces";
import {useTranslation} from "react-i18next";

export interface ColumnProps {
    id?: string;
    title?: string;
    body?: ((rowData: BackOfficeAccountProps) => JSX.Element) | ((rowData: ServerProps) => JSX.Element) | ((rowData: ApplicativeAccountsProps) => JSX.Element) | ((data: DataTableValue) => JSX.Element) | undefined;
}

interface DataTableProps {
    data?: DataTableValue[] | undefined
    renderHeader?: (() => JSX.Element) | undefined | boolean;
    columns: ColumnProps[];
    paginatorTemplate?: string;
    rows?: number;
    onRowClick?: () => void;
    paginator?: boolean;
    sortable?: boolean;
}

const DataTable = ({
                       data,
                       columns,
                       renderHeader,
                       rows = 5,
                       paginator,
                   }: DataTableProps) => {
    const {t} = useTranslation()
    const renderColumns = () =>
        columns?.map((column) => (
            <Column key={column.id} field={column.id} header={column.title} body={column.body} className={column.id}/>
        ));


    return (
        <PrimeDataTable
            scrollable
            scrollHeight="400px"
            value={data}
            paginator={paginator}
            header={renderHeader}
            rows={rows}
            emptyMessage={t('general.DataTable.empty')}
        >
            {renderColumns()}
        </PrimeDataTable>
    );
};

export default DataTable;
