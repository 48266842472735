import { InputContainer, InputText } from "./Style";
import {ChangeEvent} from "react";

export interface InputProps {
    value?: string;
    onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
    onKeyPress?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
    placeholder?: string;
    className?: string;
    label?: string;
    type?: string;
    icon?: string;
    dataTestId?: string;
}

const Input = ({
                   value,
                   icon,
                   onChange,
                   onKeyPress,
                   placeholder,
                   className,
                   type,
                   dataTestId
               }: InputProps) => {

    return (
        <InputContainer>
      <span className="p-input-icon-left">
          <i className={icon}/>
          <InputText
              type={type}
              value={value}
              onChange={onChange}
              onKeyPress={onKeyPress}
              placeholder={placeholder}
              className={className}
              data-testid={dataTestId}
          />
      </span>
        </InputContainer>
    );
};

export default Input;
