import {DefaultTheme} from "styled-components";

export const Theme: DefaultTheme = {
    /* Background Colors */

    background_main_grey: '#f6f8fb',
    background_main_blue: '#edf1f5',

    /* Main Colors */

    color_action: '#3636B9',
    color_authentification_page: '#4141c8',
    color_information: '#2DB0FF',
    color_critical: '#FF5252',
    color_confirmation: '#2CBF87',
    color_warning: '#F3B63B',
    color_button_informations: '#ffffff54',
    color_authentication_background:'#1b2a4e',
    color_authentication_password_must_contain:'#F8FAFF',
    color_authentication_button_hover:'#4545c9',

    /* Purple */

    color_purple__100: '#E6E6F9',
    color_purple__200: '#D3D3F7',
    color_purple__300: '#8787D9',
    color_purple__400: '#152D62',

    /* Grey */
    color_white: '#FFFFFF',
    color_grey__100: '#F8FAFF',
    color_grey__200: '#F2F4FB',
    color_grey__300: '#D5DFEA',
    color_grey__400: '#B6C7D9',
    color_grey__500: '#74889D',
    color_grey__600: '#46525F',
    color_grey__700: '#33333B',
    color_black: '#000000',

    // Font families
    fontFamilyBase: 'Roboto, sans-serif',
    fontFamilySemiBold: 'Roboto Medium, sans-serif',
    fontFamilyBold: 'Roboto Bold, sans-serif',

    // Font sizes
    lineHeightBase: '1.5',
    fontSizeHtml: '62.5%',
    fontSizeBase: '1.4rem',

    elevation__0: '0rem 0.4rem 2rem rgba(65, 74, 85, 0.1)',
    elevation__1: '0rem 0rem 7.2rem rgba(65, 74, 85, .15)',
    elevation__2: '0rem 0rem 6rem 0rem #E4E7EB',
    elevation__3: '0rem 0.4rem 1.6rem rgba(65, 74, 85, .15)',
    elevation__4: '0rem 0rem 0.4rem rgba(65, 74, 85, .15)',
};

export default Theme;