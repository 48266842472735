import {Column as PrimeColumn} from 'primereact/column';
import {DataTable as PrimeDataTable} from 'primereact/datatable';
import styled from 'styled-components';

export const DataTable = styled(PrimeDataTable)`
    display: flex;
    flex-direction: column;
    box-shadow: 0px 2px 4px #5060704d;

    .p-datatable-table {
        justify-content: center;
        margin: 0;
    }

    .p-datatable-tbody {
        tr {
            &:hover {
                background-color: ${({theme}) => theme.color_grey__300};
            }
        }
    }
    

    tbody {
        tr {
            position: relative;

            &:hover {
                background-color: ${({theme}) => theme.color_grey__300};
            }
        }
    }

    .data-title {
        font-size: 1.2rem;
        font-weight: 500;
        color: ${({theme}) => theme.color_grey__800};
    }

    .p-link {
        border-radius: 50px;
    }
`;

export const Column = styled(PrimeColumn)``;
