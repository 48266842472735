import styled from "styled-components";

export const DeleteAccountModal = styled.div`
    .actions-validationButton {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        margin-top: 40px;

        .button-cancel {
            &:hover {
                background-color: ${({theme}) => theme.color_grey__600};
                color: ${({theme}) => theme.color_white};
            }

            background-color: transparent;
            border: none;
            color: ${({theme}) => theme.color_action};
            cursor: pointer;
            margin-right: 30px;
        }

    }
`;
