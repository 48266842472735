import DyduLogo from "src/Assets/Images/dydu-logo.svg";
import {Menubar as HeaderMenu} from "./Style";
import {useUserContext} from '../../Contexts/UserContext';
import {useTranslation} from "react-i18next";
import {APP_PATH} from "../../Tools/Constants";
import {useNavigate} from "react-router-dom";


const Header = () => {
    const {t} = useTranslation();
    const {casClient, encodedNextUrl, serversWithBackOfficeManagerRight} = useUserContext();
    const navigate = useNavigate();


    const start = () => {
        return (
            <div className="dydu-backoffice-logo">
                <img alt="logo" src={DyduLogo}/>
                <p>{t('general.Header.title')}</p>
            </div>
        );
    };


    // need to be dynamic with the account connected to provide the account.login
    const accounting: {
        label: string,
        command?: () => void,
        url?: string
    }[] = serversWithBackOfficeManagerRight?.length > 0 ? [{
        label: t('general.Header.backOfficeManagement'),
        command: () => navigate(APP_PATH.BACKOFFICE_ACCOUNTS),
    }, {
        label: t('general.Header.Logout'),
        command: () => casClient.logout(encodedNextUrl),
    }] : [{
        label: t('general.Header.Logout'),
        command: () => casClient.logout(encodedNextUrl),
    }]


    const items = [
        {
            label: t('general.Header.accountLogin'),
            items: accounting,
        },
    ];

    return <HeaderMenu model={items} start={start}/>;
};

export default Header;
