import styled from "styled-components";

export const MainContainer = styled.div`
    display: flex;
    flex-direction: column;
    overflow: hidden;
    background-color: ${({theme}) => theme.background_main_grey};


    .content {
        display: flex;
        flex-direction: row;
    }

`;