import styled from "styled-components";

export const BackOfficeAccountContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 50px;
    width: 100%;
    background-color: inherit;
    padding: 30px 100px 30px 30px;
    line-height: 1.5rem;
    overflow: hidden;

    .p-datatable-wrapper {
        .droits {
            background: none;
        }

        button {
            border: none;
            color: ${({theme}) => theme.color_action};
            cursor: pointer;
            margin-right: 15px;
        }
    }

    h2 {
        font-size: 20px;
        margin-bottom: 40px;
        color: ${({theme}) => theme.color_grey__600};
    }

    .actions-button {
        display: flex;
        flex-direction: row;

        button {
            background: none !important;
            border: none;
            color: ${({theme}) => theme.color_action};
            cursor: pointer;
            margin-right: 30px;
            width: 30%;
        }
    }

`;

export const NewAccountRightsModal = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    white-space: nowrap;

    p {
        margin-top: 40px;
        font-size: 12px;
        text-decoration: underline;
    }

    .title-update-rights {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .actions-multiSelect {
        margin-top: 20px;
    }

    h4 {
        color: ${({theme}) => theme.color_action};
    }

    .actions-addAccount {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        margin-top: 40px;

        .button-cancel {
            &:hover {
                background-color: ${({theme}) => theme.color_critical};
                color: ${({theme}) => theme.color_white};
            }

            background-color: transparent;
            border: none;
            color: ${({theme}) => theme.color_action};
            cursor: pointer;
            margin-right: 30px;
        }
    }


    button {
        border: none;
        cursor: pointer;
    }
}

.select-servers {
    margin-top: 10px;

    .actions-multiSelect {
        h2 {
            font-size: 10px;
        }
    }
`;

export const DeleteAccountModal = styled.div`
    .actions-deleteAccount {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        margin-top: 40px;

        .button-cancel {
            &:hover {
                background-color: ${({theme}) => theme.color_critical};
                color: ${({theme}) => theme.color_white};
            }

            background-color: transparent;
            border: none;
            color: ${({theme}) => theme.color_action};
            cursor: pointer;
            margin-right: 30px;
        }

    }
`;

export const HeaderDataTableContainer = styled.div`
    display: flex;
    align-items: center;

    button {
        background-color: ${({theme}) => theme.color_action} !important;
        color: ${({theme}) => theme.color_white};
        cursor: pointer;
        margin-left: 10px;
    }

    input {
        width: 100%;
        margin-right: 20px;
    }
`;
