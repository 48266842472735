import 'react-toastify/dist/ReactToastify.css';

import {toast} from 'react-toastify';

export const toastAccountAlreadyExist = (message?: string) => {
    toast.error(message || 'Account already exist please use modify button', {
        position: toast.POSITION.TOP_RIGHT,
    });
}

export const toastSuccessUpdatingAccount = (message?: string) => {
    toast.success(message, {
        position: toast.POSITION.TOP_RIGHT,
    });
}

export const toastSuccessDeleteAccount = (message?: string) => {
    toast.success(message, {
        position: toast.POSITION.TOP_RIGHT,
    });
}

export const toastError = (message?: string) => {
    toast.error(message || 'Une erreur est survenue', {
        position: toast.POSITION.TOP_RIGHT,
    });
}