import styled from "styled-components";

export const ApplicativeAccountsManagementContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    .title-applicativeAccount {
        align-self: flex-start;
        margin-left: 20px;
    }

    .button-back {
        background-color: ${({theme}) => theme.color_white};
        border: ${({theme}) => `1px solid ${theme.color_action}`};
        color: ${({theme}) => theme.color_action};

        &:hover {
            background-color: ${({theme}) => theme.color_action};
            color: ${({theme}) => theme.color_white};
        }
    }
`

export const AccountsManagementWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 100%;
    margin: 50px;

    .p-datatable-table {
        font-size: 10px;
    }

    .p-multiselect {
        width: 50%;
    }

    .form-title {
        font-size: 16px;
    }

    .p-multiselect-header {
        font-size: 12px;
        background-color: red;
    }

    .serverList-container {
        margin-top: 60px;
    }

    h3 {
        font-size: 14px;
    }

    h2 {
        font-size: 16px;
    }

    p {
        font-size: 14px;
    }
`
export const BmsManagementContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    flex: 1;

    .list-servers {
        margin-top: 20px;
        height: 400px;
        overflow-y: auto;
    }

    .server {
        margin-top: 20px;
        margin-right: 20px;
    }

    .p-checkbox {
        margin-right: 5px;
    }

    button {
        margin-top: 20px;
    }
`

export const BoManagementContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;
    width: 100%;
    white-space: nowrap;

    .serverList-container {
        width: 100%;
    }

    .button-save {
        margin-top: 20px;
    }
`