import {useTranslation} from "react-i18next";
import DataList from "../../../Components/DataList/DataList";
import Loader from "../../../Components/Loader/Loader";
import ModalDatalist from "../../../Components/ModalDatalist/ModalDatalist";
import {useNavigate} from "react-router-dom";
import {ApplicativeAccountsProps, ServerProps} from "../../../Tools/Interfaces";
import {APP_PATH} from "../../../Tools/Constants";
import {useUserContext} from "../../../Contexts/UserContext";

const ServersWithApplicativesUsersRights = () => {
    const {t} = useTranslation();
    const {applicativeAccounts, serversWithBackOfficeUserRight} = useUserContext();
    const navigate = useNavigate();


    // columnsServers below is an array of objects that we use to create the columns of the table and the body property is used
    // to display the number of users who have access to the server. there is only servers where the connected user has access to
    const columnsServers = [
        {
            id: "1",
            title: t("general.ServersTable.server"),
            body: (rowData: ServerProps) => {
                return <div>{rowData.code}</div>
            }
        },
        {
            id: "2",
            title: t("general.ServersTable.usersBo"),
            body: (rowData: ServerProps) => {
                const usersBo = applicativeAccounts.filter((user: ApplicativeAccountsProps) => {
                    return user.applicativeRights.accountServersWithBoAccess.find((server: {
                        serverId?: string
                    }) => server.serverId === rowData.uuid);
                });
                return <ModalDatalist rowDataValue={usersBo} rights={usersBo.length} columnField={"login"}
                                      headerTitle={t('general.UsersTable.modalTitle')}/>
            }

        },
        {
            id: "3",
            title: t("general.ServersTable.usersBms"),
            body: (rowData: ServerProps) => {
                const usersBms = applicativeAccounts.filter((user: ApplicativeAccountsProps) => {
                    return user.applicativeRights.accountServersWithBmsAccess.find((server: {
                        serverId?: string
                    }) => server.serverId === rowData.uuid);
                });
                return <ModalDatalist rowDataValue={usersBms} rights={usersBms.length} columnField={"login"}
                                      headerTitle={t('general.UsersTable.modalTitle')}/>
            }
        },
        {
            id: "4",
            title: t("general.ServersTable.actions"),
            body: (rowData: ServerProps) => {
                return <button id={rowData.uuid}
                               onClick={() => navigate(APP_PATH.APPLICATIVE_SERVERS_MANAGEMENT.replace(":serverId", rowData.uuid))}>{t("general.ServersTable.accessUsers")}</button>
            }
        },
    ];

    return (
        serversWithBackOfficeUserRight?.length === 0 ? <Loader/> :
            <div>
                <DataList columns={columnsServers} data={serversWithBackOfficeUserRight}/>
            </div>
    )
}

export default ServersWithApplicativesUsersRights