import {InputText as PrimeReactInputText} from "primereact/inputtext";
import styled from "styled-components";

export const InputText = styled(PrimeReactInputText)`
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  font-size: 14px;
  min-height: 2.4rem;
  padding: 0.85rem 0 0.85rem 0.85rem;
  width: 100%;
  padding-left: 30px;

  &::placeholder {
    font-size: 12px;
    color: ${({theme}) => theme.color_grey__400};
  }
`;

export const InputContainer = styled.div`
  .labelText {
    font-size: 12px;
    font-family: "Assistant Bold";
  }

  .p-input-icon-left {
    width: 100%;

    i {
      position: absolute;
      top: 50%;
    }
  }
`;