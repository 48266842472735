import {DataListContainer} from "./Style";
import SelectButton from "src/Components/SelectButton/SelectButton";
import ApplicativeAccounts from "./Components/ApplicativeAccounts";
import ServersWithApplicativesUsersRights from "./Components/ServersWithApplicativesUsersRights";
import Loader from "../../Components/Loader/Loader";
import {useTranslation} from "react-i18next";
import {useUserContext} from "../../Contexts/UserContext";

const HomePage = () => {


    const {t} = useTranslation();
    const options = [t('general.Button.toggleUsers'), t('general.Button.toggleServers')];

    const {
        isLoading, homePageDisplayOption, setHomePageDisplayOption
    } = useUserContext();

    const ToggleAccountsServers = (e: { value: string }) => {
        setHomePageDisplayOption(e.value);
    };

    return (
        <DataListContainer>
            <div className="toggle-button">
                <SelectButton
                    options={options}
                    value={homePageDisplayOption}
                    onChange={ToggleAccountsServers}
                    disabled={false}
                />
            </div>
            {isLoading ? <Loader/> :
                <div className="homepage-datatable">
                    {homePageDisplayOption === options[0] ?
                        <ApplicativeAccounts/> :
                        <ServersWithApplicativesUsersRights/>}
                </div>}
        </DataListContainer>
    );
};

export default HomePage;
