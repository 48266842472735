import i18next from "i18next";
import {initReactI18next} from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import translationFR from "src/Services/fr/translation.json";
import translationEN from "src/Services/en/translation.json";

i18next
    .use(initReactI18next)
    .use(LanguageDetector)
    .init({
        detection: {
            lookupCookie: 'i18next',
            lookupFromPathIndex: 0,
            lookupFromSubdomainIndex: 0,
            lookupLocalStorage: 'i18nextLng',
            lookupSessionStorage: 'persist:DYDU',
            order: ['localStorage', 'sessionStorage', 'navigator', 'querystring', 'path', 'htmlTag', 'subdomain'],
        },
        // resources is our translation object in json format (key-value pair)
        resources: {
            fr: {
                translation: translationFR,
            },
            en: {
                translation: translationEN,
            }
        },
        cleanCode: true,
        debug: false,
        fallbackLng: "fr",
        supportedLngs: ['en', 'fr'],
    });
export default i18next;
