import Input from "../../../Components/Input/Input";
import Button from "../../../Components/Button/Button";
import {ChangeEvent, Dispatch, SetStateAction, useState} from "react";
import {
    addApplicativesRightsForSelectedAccount,
    updateApplicativeRightsForSelectedAccount
} from "../../../Tools/Api";
import {toastAccountAlreadyExist, toastSuccessUpdatingAccount} from "../../../Tools/Toast";
import {DataTableValue} from "primereact/datatable";
import {
    updateApplicativesRightsOnAccount,
    isEmailIsValidFormat,
    isNewAccountAlreadyExist
} from "../../../Tools/Utilities";
import {ApplicativeAccountsProps, ApplicativeUpdateRightsProps} from "../../../Tools/Interfaces";
import {useTranslation} from "react-i18next";
import {useUserContext} from "../../../Contexts/UserContext";

interface AddRightsToAccountProps {
    serverId?: string;
    setAccountsWithBoRightsOnSelectedServer: Dispatch<SetStateAction<ApplicativeUpdateRightsProps[]>>;
    accountsWithBoRightsOnSelectedServer: ApplicativeUpdateRightsProps[];
    setSearchTerm: Dispatch<SetStateAction<string>>;
}

const AddRightsToAccount = ({
                                serverId,
                                setAccountsWithBoRightsOnSelectedServer,
                                accountsWithBoRightsOnSelectedServer,
                                setSearchTerm,
                            }: AddRightsToAccountProps) => {
    const [newAccount, setNewAccount] = useState<string>("");
    const {t} = useTranslation();
    const {casClient, applicativeAccounts, setApplicativeAccounts, serversWithBackOfficeUserRight} = useUserContext();

    const handleNewAccount = (e: ChangeEvent<HTMLInputElement>) => {
        setNewAccount(e.target.value.toLowerCase())
    }
    const isNewAccountAlreadyExistInList = (list: ApplicativeAccountsProps[] | DataTableValue[]) => {
        return list?.some((account: DataTableValue) => account.login === newAccount);
    }

    const updateApplicativeAccounts = (newUser: ApplicativeAccountsProps) => {
        const isAccountAlreadyInApplicativesAccounts = applicativeAccounts.some((user) => user.login === newUser.login)
        if (isAccountAlreadyInApplicativesAccounts) {
            return applicativeAccounts.map((user) => {
                if (user.login === newUser.login) {
                    return newUser;
                }
                return user
            });
        } else {
            return [...applicativeAccounts, newUser]
        }
    }


    const handleAddAccount = () => {
        const userToUpdate = applicativeAccounts.find((user) => user.login === newAccount);
        const serversWithBoAccessForUserToUpdate = userToUpdate ? userToUpdate.applicativeRights.accountServersWithBoAccess : [];


        const newAccountToAdd = {
            login: newAccount,
            applicativeRights: {
                accountServersWithBoAccess: [...serversWithBoAccessForUserToUpdate, {
                    serverId: serverId,
                    buType: 2
                }]
            }
        }
        const serverCode = serversWithBackOfficeUserRight.find((_server: { uuid: string }) => {
            const accountServersEntries = newAccountToAdd.applicativeRights.accountServersWithBoAccess.entries();
            const nextEntry = accountServersEntries?.next();
            const serverId = nextEntry?.value?.[1]?.serverId;
            return _server.uuid === serverId;
        });

        if (isEmailIsValidFormat(newAccount) && !isNewAccountAlreadyExistInList(applicativeAccounts)) {
            addApplicativesRightsForSelectedAccount(newAccountToAdd, casClient.getToken(), (serverCode != undefined ? serverCode.code : ""), false).then((response) => {
                toastSuccessUpdatingAccount()
                response && setApplicativeAccounts(updateApplicativeAccounts(updateApplicativesRightsOnAccount(response, t)))
                setAccountsWithBoRightsOnSelectedServer([...accountsWithBoRightsOnSelectedServer, newAccountToAdd]);
                setSearchTerm(newAccountToAdd.login || "")
            })
        } else if (isNewAccountAlreadyExistInList(applicativeAccounts) && !isNewAccountAlreadyExistInList(accountsWithBoRightsOnSelectedServer)) {
            updateApplicativeRightsForSelectedAccount(newAccountToAdd, casClient.getToken(), (serverCode != undefined ? serverCode.code : ""),false).then((response) => {
                toastSuccessUpdatingAccount()
                response && setApplicativeAccounts(updateApplicativeAccounts(updateApplicativesRightsOnAccount(response, t)))
                setAccountsWithBoRightsOnSelectedServer([...accountsWithBoRightsOnSelectedServer, newAccountToAdd]);
                setSearchTerm(newAccountToAdd.login || "")
            })
        } else {
            toastAccountAlreadyExist()
        }
    }


    return (
        <div className="add-account-rights">
            <div>
                <Input placeholder={t('general.Input.email')} onChange={handleNewAccount} value={newAccount}/>
            </div>
            <div>
                <Button label={t('general.Button.add')}
                        onClick={handleAddAccount}
                        disabled={!isEmailIsValidFormat(newAccount) || isNewAccountAlreadyExist(accountsWithBoRightsOnSelectedServer, newAccount)}/>
            </div>
        </div>
    )
}

export default AddRightsToAccount