import styled from "styled-components";

export const DataListContainer = styled.div`
    .input-search {
        width: 30%;
        margin-bottom: 30px;
    }

    .p-input-icon-left {
        display: initial;
    }

    .header-add-account {
        display: flex;
        align-items: center;
        flex-direction: row;

        button {
            background: none !important;
            border: none;
            color: ${({theme}) => theme.color_action};
            cursor: pointer;
            margin-left: 20px;
        }

        input {
            width: 100%;
            margin-right: 20px;
        }
    }
`;