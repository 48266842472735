import {useEffect} from 'react';
import {useUserContext} from '../../Contexts/UserContext';
import {encodedServiceUrl} from "../../Tools/Api";
import {APP_PATH} from "../../Tools/Constants";
import {useNavigate} from 'react-router-dom';

const Auth = () => {
  console.log('auth displayed ' + window.location.href);
  const { casClient } = useUserContext();
  const navigate = useNavigate();

  useEffect(() => {
    if (casClient.login) { // cas client instantiated with a login, can redirect if needed to cas login page with no troubles
      casClient.checkSession(true, encodedServiceUrl);
    } else {
      console.log('cas client instantiated with no login, redirect to login page');
      navigate(APP_PATH.LOGIN);
    }
  }, []);

  return <></>;
};

export default Auth;
