import AccountsWithBmsRights from "./Components/AccountsWithBmsRights";
import {ServerProps} from "../../Tools/Interfaces";
import AccountsWithBoRights from "./Components/AccountsWithBoRights";
import {AccountsRightsManagementContainer} from "./Style";
import {useTranslation} from "react-i18next";
import {useParams, useNavigate} from "react-router-dom";
import Button from "../../Components/Button/Button";
import {useUserContext} from "../../Contexts/UserContext";

const ApplicativeServersManagement = () => {
    const navigate = useNavigate();
    const serverId = useParams<{ serverId: string }>();
    const {t} = useTranslation();
    const {serversWithBackOfficeUserRight, applicativeAccounts} = useUserContext();


    const nameOfSelectedServer = serversWithBackOfficeUserRight?.find((server: ServerProps) => {
        return server.uuid === serverId.serverId;
    });


    return (
        <AccountsRightsManagementContainer>
            <h2>{t('general.ServerManagement.title')}{nameOfSelectedServer?.code}</h2>
            <div className="rights-management">
                <div>
                    <AccountsWithBoRights/>
                </div>
                <div>
                    <AccountsWithBmsRights applicativeAccounts={applicativeAccounts}/>
                </div>
            </div>
            <Button className="button-back" icon="pi pi-arrow-left" onClick={() => navigate(-1)}
                    label={t('general.Button.back')}/>
        </AccountsRightsManagementContainer>
    )
}

export default ApplicativeServersManagement;