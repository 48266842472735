import {LoginPageContainer} from "./Style";
import Button from "../../Components/Button/Button";
import Input from "../../Components/Input/Input";
import React, {ChangeEvent, useState} from "react";
import {useUserContext} from "../../Contexts/UserContext";
import {useTranslation} from "react-i18next";
import {encodedServiceUrl} from "../../Tools/Api";
import {isEmailIsValidFormat} from "../../Tools/Utilities";

const Login = () => {
    const [login, setLogin] = useState<string>("");
    const {casClient} = useUserContext();
    const {t} = useTranslation();

    const handleLogin = () => {
        if (isEmailIsValidFormat(login)) {
            casClient.setLogin(login);
            casClient.checkSession(true, encodedServiceUrl);
        }
    };

    const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === "Enter") {
            handleLogin();
        }
    };

    if (window.location.href.includes("&ticket=")) {
        // cas ask app to validate ticket
        console.log('login not displayed, display blank instead ' + window.location.href);
        return <></>;
    }

    console.log('login displayed ' + window.location.href);
    return <LoginPageContainer>
        <h1>{t('general.Login.whoWantToConnect')}</h1>
        <Input className="input-add-account" placeholder="email@example.com"
               type="email"
               value={login}
               onChange={(e: ChangeEvent<HTMLInputElement>) => {
                   setLogin(e.target.value.toLowerCase())
               }}
               onKeyPress={handleKeyPress}
        />
        <Button disabled={!isEmailIsValidFormat(login)} label={t('general.Login.goToLoginPage')} onClick={handleLogin}/>

    </LoginPageContainer>;
};

export default Login;
