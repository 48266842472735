import {deleteApplicativeRightsForSelectedAccount} from "../../../Tools/Api";
import {toastSuccessDeleteAccount} from "../../../Tools/Toast";
import {DeleteAccountModal} from "../Style";
import {ApplicativeAccountsProps} from "../../../Tools/Interfaces";
import {useTranslation} from "react-i18next";
import ValidationButtons from "../../../Components/ValidationButtons/ValidationButtons";
import {useUserContext} from "../../../Contexts/UserContext";

interface DeleteBackOfficeRightsModalProps {
    accountToDelete: string;
    handleCloseDeleteRights: () => void;
    setApplicativeAccounts: (applicativeAccounts: ApplicativeAccountsProps[]) => void;
    applicativeAccounts: ApplicativeAccountsProps[];
}

const DeleteApplicativesRights = ({
                                      accountToDelete,
                                      handleCloseDeleteRights,
                                      setApplicativeAccounts,
                                      applicativeAccounts,
                                  }: DeleteBackOfficeRightsModalProps) => {
    const {t} = useTranslation();
    const {casClient} = useUserContext();

    const handleDeleteAccount = () => {
        deleteApplicativeRightsForSelectedAccount(accountToDelete, casClient.getToken())
            .then(() => {
                toastSuccessDeleteAccount(t('general.Toasts.successDelete'));
                handleCloseDeleteRights();
                setApplicativeAccounts(applicativeAccounts.filter((backOfficeAccount: ApplicativeAccountsProps) => backOfficeAccount.login !== accountToDelete));
            }).catch((err) => {
            console.log(err);
        });

    }

    return (
        <DeleteAccountModal>
            <div>
                <p>{t("general.AccountManagement.modalDelete")}</p>
                <div className="actions-deleteAccount">
                    <ValidationButtons onClickCancel={handleCloseDeleteRights} onClickValidate={handleDeleteAccount}
                                       labelValidate={t('general.Button.validate')}
                                       labelCancel={t('general.Button.cancel')}/>
                </div>
            </div>
        </DeleteAccountModal>

    );
}

export default DeleteApplicativesRights