import {createContext, useContext, useEffect, useState} from 'react';
import Cas from '@dydu_ai/dydu-cas';
import {
    casClientName,
    casLoginMessage,
    casUrl, appRoot, getApplicativeAccounts,
    getServersWithBackOfficeUserRight, getServersWithBackOfficeManagerRight,
    serverCode
} from "../Tools/Api";
import * as Constants from '../Tools/Constants'
import {useTranslation} from "react-i18next";
import {ApplicativeAccountsProps, ServerProps} from "../Tools/Interfaces";
import {AUTH_PATH} from "../Tools/Constants";

export interface UserContextProps {
    login: string;
    setLogin: (user: string) => void;
    homePageDisplayOption: string;
    setHomePageDisplayOption: (homePageDisplayOption: string) => void;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    casClient: any;
    nextUrl: string;
    encodedNextUrl: string;
    serversWithBackOfficeUserRight: ServerProps[];
    serversWithBackOfficeManagerRight: ServerProps[];
    setServersWithBackOfficeUserRight: (servers: ServerProps[]) => void;
    setServersWithBackOfficeManagerRight: (servers: ServerProps[]) => void;
    applicativeAccounts: ApplicativeAccountsProps[];
    setApplicativeAccounts: (applicativeAccounts: ApplicativeAccountsProps[]) => void;
    isLoading: boolean;
    setIsLoading: (isLoading: boolean) => void;

}

export const UserContext = createContext({} as UserContextProps);

export const useUserContext = () => useContext<UserContextProps>(UserContext);

enum QueryParams {
    login = 'login',
}

const UserProvider = ({children}: { children: React.ReactNode }) => {
    const {t} = useTranslation();
    const [user, setUser] = useState<string>('');
    const [homePageDisplayOption, setHomePageDisplayOption] = useState<string>(t('general.Button.toggleUsers'));
    const [serversWithBackOfficeUserRight, setServersWithBackOfficeUserRight] = useState<ServerProps[]>([]);
    const [serversWithBackOfficeManagerRight, setServersWithBackOfficeManagerRight] = useState<ServerProps[]>([]);
    const [applicativeAccounts, setApplicativeAccounts] = useState<ApplicativeAccountsProps[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);

    let url = window.location.href;
    console.log('userProvider run ' + window.location.href);
    if (url.includes(AUTH_PATH)) {
        url = url.replace('/#/', '/');
        const location = new URL(url);
        const queryParameters = new URLSearchParams(location.search);

        const login = queryParameters.get(QueryParams.login);
        login && sessionStorage.setItem(Constants.LOGIN, login);
        console.log('Login stored in session: ' + sessionStorage.getItem(Constants.LOGIN));
    }

    useEffect(() => {
        if (casClient.getToken()) {
            getServersWithBackOfficeUserRight(casClient.getToken()).then((res: ServerProps[]) =>
                setServersWithBackOfficeUserRight(res)
            ).catch((err) => {
                console.log(err);
            });
        }
    }, []);

    useEffect(() => {
        if (casClient.getToken()) {
            getServersWithBackOfficeManagerRight(casClient.getToken()).then((res: ServerProps[]) => {
                return setServersWithBackOfficeManagerRight(res);
            }).catch((err) => {
                console.log(err);
            });
        }
    }, []);

    useEffect(() => {
        if (casClient.getToken()) {
            getApplicativeAccounts(casClient.getToken()).then((res) => {
                setApplicativeAccounts(res.map((applicativeAccount: ApplicativeAccountsProps) => {
                    const boUserOnAllServers = applicativeAccount?.applicativeRights?.boUserOnAllServers;
                    const boUserOnNoneServers = applicativeAccount?.applicativeRights?.boUserOnNoneServers;
                    const bmsUserOnAllServers = applicativeAccount?.applicativeRights?.bmsUserOnAllServers;
                    const bmsUserOnNoneServers = applicativeAccount?.applicativeRights?.bmsUserOnNoneServers;
                    applicativeAccount.boRights = boUserOnNoneServers ? t('general.UsersTable.noneServer') : boUserOnAllServers ? t('general.UsersTable.allServer') : applicativeAccount?.applicativeRights?.accountServersWithBoAccess?.length;
                    applicativeAccount.bmsRights = bmsUserOnNoneServers ? t('general.UsersTable.noneServer') : bmsUserOnAllServers ? t('general.UsersTable.allServer') : applicativeAccount?.applicativeRights?.accountServersWithBmsAccess?.length;
                    return {...applicativeAccount, Actions: <button>{t('general.Button.modify')}</button>}
                }));
                setIsLoading(false);
            }).catch((err) => {
                console.log(err);
            });
        }
    }, []);


    const login = sessionStorage.getItem(Constants.LOGIN) || '';
    const cleanAppRoot = appRoot !== '/' ? appRoot : '';
    const nextUrl = new URL(window.location.href).origin + cleanAppRoot + AUTH_PATH + (login !== '' ? '?login=' + login : '');
    const encodedNextUrl = encodeURIComponent(nextUrl);

    const casClient = Cas.getInstance(casUrl, serverCode, login,
        casLoginMessage, casClientName, cleanAppRoot + AUTH_PATH);
    console.log('cas instantiated with ' + login);

    const props: UserContextProps = {
        login: user,
        setLogin: setUser,
        homePageDisplayOption,
        setHomePageDisplayOption,
        casClient,
        nextUrl: nextUrl,
        encodedNextUrl: encodedNextUrl,
        serversWithBackOfficeUserRight,
        serversWithBackOfficeManagerRight,
        setServersWithBackOfficeUserRight,
        setServersWithBackOfficeManagerRight,
        applicativeAccounts,
        setApplicativeAccounts,
        isLoading,
        setIsLoading,
    };

    return <UserContext.Provider children={children} value={props}/>;
};

export default UserProvider;