import {MultiSelect as PrimeMultiSelect} from "primereact/multiselect";
import styled from "styled-components";

export const MultiSelectContainer = styled(PrimeMultiSelect)`
    flex: 1;
`

export const MultiSelectWrapper = styled.div`
    .actions-multiSelect {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        button {
            display: none;
        }

        h2 {
            flex: 1;
            font-size: 13px;
            margin-right: 10px;
        }
    }
`

