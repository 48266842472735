import styled from "styled-components";

export const DataListContainer = styled.div`
    display: flex;
    flex-direction: column;
    min-height: 100%;
    width: 100%;
    padding: 30px 100px 30px 30px;
    line-height: 1.5rem;
    overflow: hidden;
    margin-top: 50px;

    .p-datatable-tbody {
        button {
            background: none !important;
            border: none;
            color: ${({theme}) => theme.color_action};
            cursor: pointer;
        }
    }


    .p-selectbutton {
        margin-bottom: 50px;
        width: 30%;
    }

    .actions-button {
        display: flex;
        flex-direction: row;

        button {
            color: ${({theme}) => theme.color_action};
            cursor: pointer;
            margin-right: 30px;
            width: 30%;
        }
    }

    .toggle-button {
        .p-button {
            font-size: 12px;
            font-weight: bold;
        }

        & .p-button:nth-child(1) {
            border-radius: 10px 0 0 10px;
        }

        & .p-button:nth-child(2) {
            border-radius: 0 10px 10px 0;
        }
    }

    .header-add-account {
        display: flex;
        align-items: center;

        button {
            background-color: ${({theme}) => theme.color_action} !important;
            color: ${({theme}) => theme.color_white};
            cursor: pointer;
            margin-left: 10px;

            &:disabled {
                color: ${({theme}) => theme.color_white};
            }
        }

        input {
            width: 100%;
            margin-right: 20px;
        }
    }
`;


export const DeleteAccountModal = styled.div`
    .actions-deleteAccount {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        margin-top: 40px;

        .button-cancel {
            &:hover {
                background-color: ${({theme}) => theme.color_critical};
                color: ${({theme}) => theme.color_white};
            }

            background-color: transparent;
            border: none;
            color: ${({theme}) => theme.color_action};
            cursor: pointer;
            margin-right: 30px;
        }

    }
`;




