import {SideBarMenu} from "./Style";
import {APP_PATH} from "../../Tools/Constants";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";

export interface SideBarProps {
    orientation?: "vertical" | "horizontal" | undefined;
}

const SideBar = ({orientation}: SideBarProps) => {
    const {t} = useTranslation();
    const navigate = useNavigate();

    const items = [
        {
            label: t('general.SideBar.usersBoBms'),
            command: () => navigate(APP_PATH.ROOT),
        },
    ];
    return (
        <SideBarMenu model={items} orientation={orientation}/>
    );
};

export default SideBar;