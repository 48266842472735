import {MainContainer} from "./Style";
import SideBar from "../SideBar/SideBar";
import Header from "../Header/Header";
import React, {Component, ElementType} from "react";

const WithLayout = (WrappedComponent: ElementType) => {
    class ComponentWrapped extends Component {
        render(): JSX.Element {


            return (
                <MainContainer>
                    <Header/>
                    <div className="content">
                        <SideBar orientation='vertical'/>
                        <WrappedComponent {...this.props}/>
                    </div>
                </MainContainer>
            );
        }
    }

    return ComponentWrapped;
};

export default WithLayout;
