import "./App.css";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import {ReactElement} from "react";
import {Router} from "./Pages/Router";
import 'react-toastify/dist/ReactToastify.css';
import {ToastContainer} from "react-toastify";


const App = (): ReactElement => {

    return (
        <>
            <Router/>
            <ToastContainer autoClose={3000}/>
        </>
    )
};

export default App;

