import styled, {css} from 'styled-components';

export const IconWrapper = styled.div`
  & + * {
    margin-left: 10px;
  }

  color: ${({color}) => color};

  ${({onClick}) =>
    onClick &&
    css`
      cursor: pointer;
    `}
`;
