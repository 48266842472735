import {MegaMenu as PrimeReactMegaMenu} from "primereact/megamenu";
import styled from "styled-components";

export const SideBarMenu = styled(PrimeReactMegaMenu)`
  font-family: "Roboto bold", sans-serif;
  font-style: italic;
  font-size: 14px;
  height: 100%;
  min-height: 100vh;
  padding-top: 50px;
  box-shadow: 0px 2px 4px #5060704d;
  width: 15%;
  overflow: hidden;
`;
