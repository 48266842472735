import {AccountsManagementWrapper, ApplicativeAccountsManagementContainer} from "./Style";
import ManageBoRights from "./Components/ManageBoRights";
import ManageBmsRights from "./Components/ManageBmsRights";
import {useParams, useNavigate} from "react-router-dom";
import Button from "../../Components/Button/Button";
import {useTranslation} from "react-i18next";

const ApplicativeAccountsManagement = () => {
    const {t} = useTranslation();
    const navigate = useNavigate();
    const {login} = useParams<{ login: string }>();


    return (
        <>
            <ApplicativeAccountsManagementContainer>
                <h2 className='title-applicativeAccount'>{t("general.AccountManagement.title")} {login}</h2>
                <AccountsManagementWrapper>
                    <div className="multiSelect-container">
                        <ManageBoRights loginForNewsRights={login}/>
                    </div>
                    <div>
                        <ManageBmsRights/>
                    </div>
                </AccountsManagementWrapper>
                <Button className="button-back" icon="pi pi-arrow-left" onClick={() => navigate(-1)}
                        label={t('general.Button.back')}/>
            </ApplicativeAccountsManagementContainer>
        </>
    );
};

export default ApplicativeAccountsManagement;
