import {SelectButton as PrimeSelectButton} from "./Style";
import {SelectButtonProps as PrimeSelectButtonProps} from "primereact/selectbutton";

export interface SelectButtonProps extends PrimeSelectButtonProps {
    options?: string[];
    onChange?: (e: { value: string; }) => void;
    value?: string;
    disabled?: boolean;
}


const SelectButton = ({options, onChange, value, disabled}: SelectButtonProps) => {

    const props = {
        options,
        onChange,
        value,
        disabled
    }

    return (
        <PrimeSelectButton {...props} />
    );
}

export default SelectButton;