import styled from "styled-components";


export const LoginPageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  height: 100vh;
  background-color: ${({theme}) => theme.background_main_grey};
  
  .input-add-account {
  margin-bottom : 20px;
  }
`;