import {Menubar as PrimeHeaderMenu} from "primereact/menubar";
import styled from "styled-components";

export const Menubar = styled(PrimeHeaderMenu)`
    background-color: ${({theme}) => theme.color_grey__200};
    border: 1px solid ${({theme}) => theme.color_grey__300};
    box-shadow: none;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .p-menuitem-text {
        font-size: 12px;
    }

    .p-submenu-list {
        max-width: 100%;
    }

    .p-menuitem-text {
        font-size: 10px;
    }
}

img {
    width: 30px;
    height: 100%;
    margin-left: 20px;
    display: flex;
}

.dydu-backoffice-logo {
    display: flex;
    align-items: center;

    p {
        margin-left: 10px;
        font-size: 12px;
    }
}
`;
