import {ProgressSpinnerContainer} from "./Style"

const Loader = () => {
    return (
        <div className="loader">
            <ProgressSpinnerContainer strokeWidth={"8"}/>
        </div>
    )
}

export default Loader