import axios, {AxiosResponse} from "axios";
import {APP_PATH} from "./Constants";
import {Navigate} from "react-router-dom";
import {ApplicativeAccountsProps, ApplicativeUpdateRightsProps, BackOfficeAccountProps} from "./Interfaces";
import {toastError} from "./Toast";

export const casUrl = window.ENV ? window.ENV.cas_url : process.env.cas_url || '';
export const appRoot = window.ENV ? window.ENV.app_root : process.env.app_root;
const url_gateway = window.ENV ? window.ENV.gateway_url : process.env.gateway_url;

// base64 of {"appWelcomeSentence":"cas.login.welcome.back-office"}
export const casLoginMessage = 'eyJhcHBXZWxjb21lU2VudGVuY2UiOiJjYXMubG9naW4ud2VsY29tZS5iYWNrLW9mZmljZSJ9';
export const casClientName = 'BackOfficeSSOClient';
export const serverCode = 'multiserver';
export const encodedServiceUrl = encodeURIComponent(
    window.location.origin +
    (window.location.pathname && window.location.pathname !== '/' ? window.location.pathname.replace(/\/+$/, '') : '') +
    '/?client_name=' +
    casClientName
);
let proxyUrl: null | string = "";
//base64 of {"appWelcomeSentence":"cas.login.welcome.bms","msgLevel":"INFO"} and {"appWelcomeSentence":"cas.login.welcome.bo","msgLevel":"INFO"}
const casLoginMessageBms = 'eyJhcHBXZWxjb21lU2VudGVuY2UiOiJjYXMubG9naW4ud2VsY29tZS5ibXMiLCJtc2dMZXZlbCI6IklORk8ifQ';
const casLoginMessageBo = 'eyJhcHBXZWxjb21lU2VudGVuY2UiOiJjYXMubG9naW4ud2VsY29tZS5ibyIsIm1zZ0xldmVsIjoiSU5GTyJ9';

const serviceBms = (serverCode: string | undefined) => {
    if (proxyUrl != null) {
        return encodeURIComponent(proxyUrl + '/website/callback?client_name=CasClient');
    }
    return encodeURIComponent('https://' + serverCode + '/website/callback?client_name=CasClient');
}

const serviceBo = (serverCode: string | undefined) => {
    if (proxyUrl != null) {
        return encodeURIComponent(proxyUrl + '/website/bo/callback?client_name=CasClient');
    }
    return encodeURIComponent('https://' + serverCode + '/website/bo/callback?client_name=CasClient');
}

export const casLoginUrlBms = (serverCode: string | undefined) : string => {
    if (serverCode != undefined) {
        return encodeURIComponent(casUrl + "/login?servercode=" + serverCode + "&loginmessage=" + casLoginMessageBms + "&service=" + serviceBms(serverCode));
    }
    return "";
}

export const casLoginUrlBo = (serverCode: string | undefined) : string => {
    if (serverCode != undefined) {
        return encodeURIComponent(casUrl + "/login?servercode=" + serverCode + "&loginmessage=" + casLoginMessageBo + "&service=" + serviceBo(serverCode));
    }
    return "";
}

export const casLoginUrlBackOffice = (login: string) : string => {
    if (serverCode != undefined) {
        return encodeURIComponent(casUrl + "/login?login=" + login + "&servercode=multiserver&loginmessage=" + casLoginMessage + "&service=" + encodedServiceUrl);
    }
    return "";
}

export const getProxyUrl = (headers) => {
    const forwardedHost = headers['x-forwarded-host'];
    if (!forwardedHost) {
        return null;
    }

    const forwardedPort = headers['x-forwarded-port'];
    let finalForwardedHost = forwardedHost;
    if (forwardedPort) {
        finalForwardedHost += `:${forwardedPort}`;
    }

    let forwardedProto = headers['x-forwarded-proto'];
    if (!forwardedProto) {
        forwardedProto = headers['x-forwarded-scheme'];
    }
    return `${forwardedProto}://${finalForwardedHost}`;
}

export const getApplicativeAccounts = async (token: string) => {
    const headers = {
        accept: "application/json",
        Authorization:
            `Bearer ${token}`,
        servercode: serverCode
    };
    try {
        const res: AxiosResponse = await axios.get(url_gateway + "/multi-server/account/applicative-rights/all", {headers});
        proxyUrl = getProxyUrl(res.headers);
        return res.data
    } catch (error) {
        <Navigate to={APP_PATH.ROOT}/>;
    }
};

// method to get all servers where requesting user has backoffice user rights
export const getServersWithBackOfficeUserRight = async (token: string) => {
    const headers = {
        accept: "application/json",
        Authorization:
            `Bearer ${token}`,
        servercode: serverCode
    };
    try {
        const res: AxiosResponse = await axios.get(url_gateway + "/multi-server/server/backoffice-user-right/all", {headers});
        proxyUrl = getProxyUrl(res.headers);
        return res.data;
    } catch (error) {
        <Navigate to={APP_PATH.ROOT}/>;
    }
}

export const getServersWithBackOfficeManagerRight = async (token: string) => {
    const headers = {
        accept: "application/json",
        Authorization:
            `Bearer ${token}`,
        servercode: serverCode
    };
    try {
        const res: AxiosResponse = await axios.get(url_gateway + "/multi-server/server/backoffice-manager-right/all", {headers});
        return res.data;
    } catch (error) {
        <Navigate to={APP_PATH.ROOT}/>;
    }
}
export const getBackOfficeAccounts = async (token: string) => {
    const headers = {
        accept: "application/json",
        Authorization:
            `Bearer ${token}`,
        servercode: serverCode
    };
    try {
        const res: AxiosResponse = await axios.get(url_gateway + "/multi-server/account/backoffice-rights/all", {headers});
        proxyUrl = getProxyUrl(res.headers);
        return res.data;
    } catch (error) {
        <Navigate to={APP_PATH.ROOT}/>;
    }
};

export const deleteBackOfficeAccount = async (login: string, token: string) => {
    const headers = {
        accept: "application/json",
        Authorization:
            `Bearer ${token}`,
        servercode: serverCode
    };
    try {
        const res: AxiosResponse = await axios.delete(url_gateway + `/multi-server/account/backoffice-rights/by-login/${login}`, {headers});
        return res.data
    } catch (error) {
        toastError();
        <Navigate to={APP_PATH.ROOT}/>;
    }
}

export const updateBackOfficeRightsForSelectedAccount = async (data: BackOfficeAccountProps, token: string, loginUrl: string) => {
    const headers = {
        accept: "application/json",
        Authorization:
            `Bearer ${token}`,
        servercode: serverCode
    };
    try {
        const res: AxiosResponse<BackOfficeAccountProps> = await axios.put(url_gateway + `/multi-server/account/backoffice-rights/update?loginUrl=` + encodeURIComponent(loginUrl),
            data
            , {headers});
        return res.data
    } catch (error) {
        toastError();
        <Navigate to={APP_PATH.ROOT}/>;
    }
}

export const addBackOfficeRightsForSelectedAccount = async (data: BackOfficeAccountProps, token: string, loginUrl: string) => {
    const headers = {
        accept: "application/json",
        Authorization:
            `Bearer ${token}`,
        servercode: serverCode
    };
    try {
        const res: AxiosResponse<BackOfficeAccountProps> = await axios.post(url_gateway + `/multi-server/account/backoffice-rights/add?loginUrl=` + encodeURIComponent(loginUrl),
            data
            , {headers});
        return res.data
    } catch (error) {
        toastError();
        <Navigate to={APP_PATH.ROOT}/>;
    }
}

export const addApplicativesRightsForSelectedAccount = async (data: ApplicativeUpdateRightsProps, token: string, servercode: string, isForBmsLogin: boolean) => {
    const headers = {
        accept: "application/json",
        Authorization:
            `Bearer ${token}`,
        servercode: serverCode
    };
    try {
        const casLogin = isForBmsLogin ? casLoginUrlBms(servercode) : casLoginUrlBo(servercode);
        const res: AxiosResponse<ApplicativeAccountsProps> = await axios.post(url_gateway + `/multi-server/account/applicative-rights/add?loginUrl=` + casLogin,
            data
            , {headers});
        return res.data
    } catch (error) {
        toastError();
    }
}
export const deleteApplicativeRightsForSelectedAccount = async (login: string, token: string) => {
    const headers = {
        accept: "application/json",
        Authorization:
            `Bearer ${token}`,
        servercode: serverCode
    };
    try {
        const res: AxiosResponse<ApplicativeAccountsProps> = await axios.delete(url_gateway + `/multi-server/account/applicative-rights/by-login/${login}`
            , {headers});
        return res.data;
    } catch (error) {
        toastError();
        <Navigate to={APP_PATH.ROOT}/>;
    }
}

export const updateApplicativeRightsForSelectedAccount = async (data: ApplicativeUpdateRightsProps, token: string, servercode: string | undefined, isForBmsLogin: boolean) => {
    const headers = {
        accept: "application/json",
        Authorization:
            `Bearer ${token}`,
        servercode: serverCode
    };
    try {
        const casLogin = isForBmsLogin ? casLoginUrlBms(servercode) : casLoginUrlBo(servercode);
        const res: AxiosResponse<ApplicativeAccountsProps> = await axios.put(url_gateway + `/multi-server/account/applicative-rights/update?loginUrl=` + casLogin,
            data
            , {headers});
        return res.data;
    } catch (error) {
        toastError();
        <Navigate to={APP_PATH.ROOT}/>;
    }
}

export const validateToken = async (jwt?: string) => {
    const headers = {
        accept: "application/json",
        Authorization:
            `Bearer ` + jwt,
        servercode: serverCode
    };
    try {
        const res: AxiosResponse = await axios.post(url_gateway + "/auth/validate-set-or-reset-password-token"
            , {token: jwt}
            , {headers});
        return res.data;
    } catch (error) {
        console.error("Error:", error);
    }
}

export const setPasswordWithToken = async (jwt?: string, newPassword?: string) => {
    const headers = {
        accept: "application/json",
        Authorization:
            `Bearer ` + jwt,
        servercode: serverCode
    };
    try {
        const res: AxiosResponse = await axios.post(url_gateway + "/auth/set-or-reset-password"
            , {newPassword: newPassword}
            , {headers});
        return res.data;
    } catch (error) {
        console.error("Error:", error);
    }
}