import MultiSelect from "src/Components/MultiSelect/MultiSelect";
import {useEffect, useState} from "react";
import Input from "../../../Components/Input/Input";
import {NewAccountRightsModal} from "../Style";
import {
    addBackOfficeRightsForSelectedAccount, casLoginUrlBackOffice,
    updateBackOfficeRightsForSelectedAccount
} from "../../../Tools/Api";
import {
    BackOfficeAccountProps,
    AddAccountRightsProps,
    AccountServerProps, ServerProps
} from "../../../Tools/Interfaces";
import {DataTableValue} from "primereact/datatable";
import ValidationButtons from "../../../Components/ValidationButtons/ValidationButtons";
import {useTranslation} from "react-i18next";
import {useUserContext} from "../../../Contexts/UserContext";
import {toastSuccessUpdatingAccount} from "../../../Tools/Toast";


const ManageBackOfficeRights = ({
                                    accountToUpdate,
                                    handleCloseAddAccount,
                                    isAccountAlreadyExistInList,
                                    setBackOfficeAccounts,
                                    backOfficeAccounts,
                                    serversWithBackOfficeManagerRight
                                }: AddAccountRightsProps) => {
    const [selectedServersForBackOfficeUserRight, setSelectedServersForBackOfficeUserRight] = useState<DataTableValue[]>([{}]);
    const [selectedServerForBackOfficeManagerRight, setSelectedServerForBackOfficeManagerRight] = useState<DataTableValue[]>([{}]);
    const {t} = useTranslation();
    const {casClient} = useUserContext();


    // method to find all server with existing rights for the account that we want to update
    useEffect(() => {
        const account: BackOfficeAccountProps | undefined = backOfficeAccounts.find((account: BackOfficeAccountProps) => account.login === accountToUpdate);
        const serversWithExistingBackOfficeUserRight: (ServerProps | undefined)[] | undefined = account?.backOfficeRights.accountServersWithUserAccess.map((server: {
            serverId?: string
        }) => {
            return serversWithBackOfficeManagerRight.find(serverConnected => serverConnected.uuid === server.serverId)
        })
        const serversWithExistingBackOfficeManagerRight: (ServerProps | undefined)[] | undefined = account?.backOfficeRights.accountServersWithManagerAccess.map((server: {
            serverId?: string
        }) => {
            return serversWithBackOfficeManagerRight.find((serverConnected: {
                uuid: string
            }) => serverConnected.uuid === server.serverId)
        });
        setSelectedServerForBackOfficeManagerRight(serversWithExistingBackOfficeManagerRight as ServerProps[]);
        setSelectedServersForBackOfficeUserRight(serversWithExistingBackOfficeUserRight as ServerProps[]);
    }, [serversWithBackOfficeManagerRight])

    const handleSelectServersForBackOfficeUserRight = (e: DataTableValue) => {
        setSelectedServersForBackOfficeUserRight(e.value);
    };
    const handleSelectServersForBackOfficeManagerRight = (e: DataTableValue) => {
        setSelectedServerForBackOfficeManagerRight(e.value);
    }
    // method to update an account with new rights or add fresh backoffice rights to an account that had already applicatives rights (bo or bms)
    const handleUpdateAccount = () => {
        const dataToUpdate: BackOfficeAccountProps = {
            login: accountToUpdate,
            backOfficeRights: {
                accountServersWithManagerAccess: selectedServerForBackOfficeManagerRight?.map((server: AccountServerProps) => {
                    return {serverId: server.uuid}
                }),
                accountServersWithUserAccess: selectedServersForBackOfficeUserRight?.map((server: AccountServerProps) => {
                    return {serverId: server.uuid}
                }),
            },
            managerRights: selectedServerForBackOfficeManagerRight?.length === serversWithBackOfficeManagerRight.length ? t('general.UsersTable.allServer') : selectedServerForBackOfficeManagerRight?.length === 0 ? t('general.UsersTable.noneServer') : selectedServerForBackOfficeManagerRight?.length,
            userRights: selectedServersForBackOfficeUserRight?.length === serversWithBackOfficeManagerRight.length ? t('general.UsersTable.allServer') : selectedServersForBackOfficeUserRight?.length === 0 ? t('general.UsersTable.noneServer') : selectedServersForBackOfficeUserRight?.length,
        }
        isAccountAlreadyExistInList ?
            updateBackOfficeRightsForSelectedAccount(dataToUpdate, casClient.getToken(), casLoginUrlBackOffice(dataToUpdate.login)).then(() => {
                toastSuccessUpdatingAccount(t('general.Toasts.successUpdate'))
                setBackOfficeAccounts(backOfficeAccounts.map((backOfficeAccount: BackOfficeAccountProps) => {
                    return backOfficeAccount.login === accountToUpdate ? dataToUpdate : backOfficeAccount;
                }));
                handleCloseAddAccount();
            })
            :
            addBackOfficeRightsForSelectedAccount(dataToUpdate, casClient.getToken(), casLoginUrlBackOffice(dataToUpdate.login)).then(() => {
                toastSuccessUpdatingAccount(t('general.Toasts.successUpdate'))
                setBackOfficeAccounts([...backOfficeAccounts, dataToUpdate]);
                handleCloseAddAccount();
            })
    }

    return (
        <NewAccountRightsModal>
            <div className="backoffice-rights-modal">
                {!isAccountAlreadyExistInList ?
                    <div className="title-add-rights">
                        <h2>{t('general.BackOfficeManagement.addModalTitle')}</h2>
                        <Input className="input-newAccount-value" value={accountToUpdate}/>
                    </div>
                    :
                    <div className="title-update-rights">
                        <h2>{t('general.BackOfficeManagement.modifyModalTitle')}</h2>
                        <h4>{accountToUpdate}</h4>
                    </div>}
                <p>{t('general.BackOfficeManagement.modalSubtitle')}</p>
                <div className="select-servers">
                    <MultiSelect
                        value={selectedServerForBackOfficeManagerRight}
                        title={t('general.BackofficeList.manager')}
                        options={serversWithBackOfficeManagerRight}
                        onChange={handleSelectServersForBackOfficeManagerRight}
                        maxSelectedLabels={1}
                        optionLabel="code"
                        placeholder={t('general.MultiSelect.noServer')}
                    />
                    <MultiSelect
                        value={selectedServersForBackOfficeUserRight}
                        title={t('general.BackofficeList.user')}
                        options={serversWithBackOfficeManagerRight}
                        onChange={handleSelectServersForBackOfficeUserRight}
                        maxSelectedLabels={1}
                        optionLabel="code"
                        placeholder={t('general.MultiSelect.noServer')}
                    />
                </div>

                <div className="actions-addAccount">
                    <ValidationButtons onClickCancel={handleCloseAddAccount} onClickValidate={handleUpdateAccount}
                                       labelValidate={t('general.Button.validate')}
                                       labelCancel={t('general.Button.cancel')}/>
                </div>
            </div>
        </NewAccountRightsModal>

    );
};
export default ManageBackOfficeRights;
