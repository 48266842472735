import Button from "../Button/Button";
import {DeleteAccountModal} from "./Style";

interface ValidationButtonsProps {
    onClickCancel: () => void;
    onClickValidate: () => void;
    labelCancel?: string;
    labelValidate?: string;
}

const ValidationButtons = ({onClickCancel, onClickValidate, labelCancel, labelValidate}: ValidationButtonsProps) => {
    return (
        <DeleteAccountModal>
            <div className="actions-validationButton">
                <Button icon="pi pi-times" severity="danger" aria-label="Cancel" label={labelCancel}
                        className="button-cancel" onClick={onClickCancel}/>
                <Button icon="pi pi-check" aria-label="Filter" label={labelValidate} className="button-save"
                        onClick={onClickValidate}/>
            </div>
        </DeleteAccountModal>
    )
}

export default ValidationButtons;