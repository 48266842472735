import {MultiSelectContainer, MultiSelectWrapper} from "./Style";
import {SelectItemOptionsType} from "primereact/selectitem";
import {DataTableValue} from "primereact/datatable";
import {useTranslation} from "react-i18next";
import React from "react";

export interface MultiSelectProps {
    title?: string;
    options?: SelectItemOptionsType;
    onChange?: (e: DataTableValue) => void;
    value?: DataTableValue[] | string;
    maxSelectedLabels?: number;
    optionLabel?: string;
    placeholder?: string;
    showSelectAll?: boolean;
    selectAll?: boolean;
    selectionLimit?: number;
}

const MultiSelect = ({
                         title,
                         onChange,
                         value,
                         maxSelectedLabels,
                         optionLabel,
                         placeholder,
                         showSelectAll,
                         options,
                         selectAll,
                         selectionLimit,
                     }: MultiSelectProps) => {
    const {t} = useTranslation();

    const numberOfSelectedItems = value?.length
    return (
        <MultiSelectWrapper>
            <div className="actions-multiSelect">
                <h2>{title}</h2>
                <MultiSelectContainer options={options} onChange={onChange} value={value} optionLabel={optionLabel}
                                      maxSelectedLabels={maxSelectedLabels} selectionLimit={selectionLimit}
                                      selectedItemsLabel={numberOfSelectedItems + ' ' + t('general.MultiSelect.selectedItems')}
                                      selectAll={selectAll} placeholder={placeholder} showSelectAll={showSelectAll}
                                      selectAllLabel={t('general.MultiSelect.selectAll')}
                                      pt={{closeButton: {style: {display: "none"}}}}
                />
            </div>
        </MultiSelectWrapper>
    );
};

export default MultiSelect;
