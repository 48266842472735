import {BackOfficeAccountContainer, HeaderDataTableContainer} from "./Style";
import {useTranslation} from "react-i18next";
import DataList from "../../Components/DataList/DataList";
import {ChangeEvent, useEffect, useState} from "react";
import Icon from "../../Components/Icon/Icon";
import {
    getBackOfficeAccounts,
} from "../../Tools/Api";
import ModalDatalist from "../../Components/ModalDatalist/ModalDatalist";
import {Dialog} from "primereact/dialog";
import ManageBackOfficeRights from "./Components/ManageBackOfficeRights";
import Input from "../../Components/Input/Input";
import Loader from "../../Components/Loader/Loader";
import {BackOfficeAccountProps, ServerProps} from "../../Tools/Interfaces";
import {useUserContext} from "../../Contexts/UserContext";
import DeleteBackofficeRightsModal from "./Components/DeleteBackOfficeRights";
import {isEmailIsValidFormat, isNewAccountAlreadyExist} from "../../Tools/Utilities";
import Button from "../../Components/Button/Button";


const BackOfficeAccounts = () => {
    const {t} = useTranslation();
    const [backOfficeAccounts, setBackOfficeAccounts] = useState<BackOfficeAccountProps[]>([]);
    const [updateVisible, setUpdateVisible] = useState<boolean>(false);
    const [deleteVisible, setDeleteVisible] = useState<boolean>(false);
    const [accountToUpdate, setAccountToUpdate] = useState<string>("");
    const [accountToAdd, setAccountToAdd] = useState<string>("");
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const {casClient, serversWithBackOfficeManagerRight} = useUserContext();

    // we use the getServersWithBackOfficeUserRight function to get the list of servers that the connected user has access to

    // we use the getAllBackOfficeAccounts function to get the list of users from the backoffice
    useEffect(() => {
        getAllBackOfficeAccounts();
    }, [])

    const handleCloseAddAccountRights = () => {
        setAccountToUpdate("");
        setAccountToAdd("");
        setUpdateVisible(false);
    }
    const handleCloseDeleteAccount = () => {
        setAccountToUpdate("");
        setAccountToAdd("");
        setDeleteVisible(false);
    }

    const getAllBackOfficeAccounts = () => {
        getBackOfficeAccounts(casClient.getToken()).then((res: BackOfficeAccountProps[]) => {
            setBackOfficeAccounts(res.map((backOfficeAccount: BackOfficeAccountProps) => {
                const managerOnAllServer = backOfficeAccount.backOfficeRights.managerOnAllServers;
                const userOnAllServer = backOfficeAccount.backOfficeRights.userOnAllServers;
                const managerOnNoneServer = backOfficeAccount.backOfficeRights.managerOnNoneServers;
                const userOnNoneServer = backOfficeAccount.backOfficeRights.userOnNoneServers;
                backOfficeAccount.managerRights = managerOnNoneServer ? t('general.UsersTable.noneServer') : managerOnAllServer ? t('general.UsersTable.allServer') : backOfficeAccount.backOfficeRights.accountServersWithManagerAccess.length;
                backOfficeAccount.userRights = userOnNoneServer ? t('general.UsersTable.noneServer') : userOnAllServer ? t('general.UsersTable.allServer') : backOfficeAccount.backOfficeRights.accountServersWithUserAccess.length;
                return {
                    ...backOfficeAccount,
                }
            }));
        }).then(() => {
            setIsLoading(false);
        }).catch((err) => {
            console.log(err);
            setIsLoading(false)
        });
    }

    const columns = [
        {
            id: "1",
            title: t('general.BackofficeList.login'),
            body: (rowData: BackOfficeAccountProps) => {
                return (
                    <div className="login">
                        {rowData.login}
                    </div>
                )
            }
        },
        {
            id: "2",
            title: t('general.BackofficeList.manager'),
            body: (rowData: BackOfficeAccountProps) => {
                const accountServersWithManagerAccess = rowData?.backOfficeRights?.accountServersWithManagerAccess?.map((server: {
                    serverId?: string
                }) => {
                    return serversWithBackOfficeManagerRight && serversWithBackOfficeManagerRight.find((serverConnected: ServerProps) => serverConnected.uuid === server.serverId) as ServerProps;
                })
                return (
                    <ModalDatalist rowDataValue={accountServersWithManagerAccess}
                                   rights={rowData.managerRights} columnField={"code"}
                                   headerTitle={t('general.BackofficeList.modalTitle')}/>
                )
            },
        },
        {
            id: "3",
            title: t('general.BackofficeList.user'),
            body: (rowData: BackOfficeAccountProps) => {
                const accountServersWithUserAccess = rowData?.backOfficeRights?.accountServersWithUserAccess?.map((server: {
                    serverId?: string
                }) => {
                    return serversWithBackOfficeManagerRight.find((serverConnected: ServerProps) => serverConnected.uuid === server.serverId) as ServerProps;
                })
                return (
                    <ModalDatalist rowDataValue={accountServersWithUserAccess}
                                   rights={rowData.userRights}
                                   columnField={"code"}
                                   headerTitle={t('general.BackofficeList.modalTitle')}/>
                )
            },
        },
        {
            id: "4",
            title: t('general.BackofficeList.actions'),
            body: (rowData: BackOfficeAccountProps) => {
                return (
                    <div className="actions-button">
                        <button onClick={() => {
                            setUpdateVisible(true);
                            setAccountToUpdate(rowData.login)
                        }}>{t('general.Button.modify')}
                        </button>
                        <Icon icon="bin" color='red' onClick={() => {
                            setDeleteVisible(true);
                            setAccountToUpdate(rowData.login)
                        }}/>
                    </div>
                )
            }
        },
    ];

    const isAccountToUpdateAlreadyExistInList = isNewAccountAlreadyExist(backOfficeAccounts, accountToUpdate);


    const renderHeader = () => (
        <HeaderDataTableContainer>
            <Input className="input-add-account" placeholder={t('general.Input.email')}
                   type="email"
                   value={accountToAdd}
                   onChange={(e: ChangeEvent<HTMLInputElement>) => {
                       setAccountToAdd(e.target.value)
                   }}
            />
            <div className="modal-addAccount">
                <Button className="add-button" data-testId="addAccount"
                        disabled={!isEmailIsValidFormat(accountToAdd) || isNewAccountAlreadyExist(backOfficeAccounts, accountToAdd)}
                        onClick={() => setUpdateVisible(true)}
                        label={t('general.Button.add')}/>
                <Dialog className="dialog-addAccount" visible={updateVisible} onHide={() => setUpdateVisible(false)}
                        closable={false} dismissableMask={true}>
                    <ManageBackOfficeRights accountToUpdate={accountToUpdate || accountToAdd}
                                            handleCloseAddAccount={handleCloseAddAccountRights}
                                            isAccountAlreadyExistInList={isAccountToUpdateAlreadyExistInList}
                                            setBackOfficeAccounts={setBackOfficeAccounts}
                                            backOfficeAccounts={backOfficeAccounts}
                                            serversWithBackOfficeManagerRight={serversWithBackOfficeManagerRight}/>
                </Dialog>
            </div>
        </HeaderDataTableContainer>
    );

    return (
        isLoading ? <Loader/> :
            <BackOfficeAccountContainer>
                <h2>{t("general.BackofficeList.title")}</h2>
                <DataList columns={columns} data={backOfficeAccounts} renderHeader={renderHeader}/>
                <Dialog visible={deleteVisible}
                        header={t("general.DeleteBackofficeAccount.titleModal") + accountToUpdate}
                        onHide={() => setDeleteVisible(false)} style={{width: '50%'}} dismissableMask={true}>
                    <DeleteBackofficeRightsModal accountToUpdate={accountToUpdate}
                                                 handleCloseDeleteAccount={handleCloseDeleteAccount}
                                                 setBackOfficeAccounts={setBackOfficeAccounts}
                                                 backOfficeAccounts={backOfficeAccounts}
                    />
                </Dialog>
            </BackOfficeAccountContainer>
    );
};

export default BackOfficeAccounts;
