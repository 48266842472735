import {deleteBackOfficeAccount} from "../../../Tools/Api";
import {toastError, toastSuccessDeleteAccount} from "../../../Tools/Toast";
import {DeleteAccountModal} from "../Style";
import {BackOfficeAccountProps} from "../../../Tools/Interfaces";
import {useTranslation} from "react-i18next";
import ValidationButtons from "../../../Components/ValidationButtons/ValidationButtons";
import {useUserContext} from "../../../Contexts/UserContext";

interface DeleteBackOfficeRightsModalProps {
    accountToUpdate: string;
    handleCloseDeleteAccount: () => void;
    setBackOfficeAccounts: (backOfficeAccounts: BackOfficeAccountProps[]) => void;
    backOfficeAccounts: BackOfficeAccountProps[];
}

const DeleteBackofficeRightsModal = ({
                                         accountToUpdate,
                                         handleCloseDeleteAccount,
                                         setBackOfficeAccounts,
                                         backOfficeAccounts
                                     }: DeleteBackOfficeRightsModalProps) => {
    const {t} = useTranslation();
    const {casClient} = useUserContext();

    const handleDeleteAccount = () => {
        deleteBackOfficeAccount(accountToUpdate, casClient.getToken()).then(() => {
            toastSuccessDeleteAccount(t('general.Toasts.successDelete'))
            handleCloseDeleteAccount();
            setBackOfficeAccounts(backOfficeAccounts.filter((backOfficeAccount: BackOfficeAccountProps) => backOfficeAccount.login !== accountToUpdate));
        }).catch((err) => {
            toastError(err)
        });

    }

    return (
        <DeleteAccountModal>
            <div>
                <p>{t("general.DeleteBackofficeAccount.modalDelete")}</p>
                <div className="actions-deleteAccount">
                    <ValidationButtons onClickCancel={handleCloseDeleteAccount} onClickValidate={handleDeleteAccount}
                                       labelValidate={t('general.Button.validate')}
                                       labelCancel={t('general.Button.cancel')}/>
                </div>
            </div>
        </DeleteAccountModal>

    );
}

export default DeleteBackofficeRightsModal