import {
    ApplicativeAccountsProps,
    ServerProps,
    AccountServerProps
} from "../../../Tools/Interfaces";
import {useEffect, useState} from "react";
import {Checkbox, CheckboxChangeEvent} from "primereact/checkbox";
import {updateApplicativeRightsForSelectedAccount} from "../../../Tools/Api";
import Button from "../../../Components/Button/Button";
import {BmsManagementContainer} from "../Style";
import {useTranslation} from "react-i18next";
import {useUserContext} from "../../../Contexts/UserContext";
import {useParams} from "react-router-dom";
import {toastSuccessUpdatingAccount} from "../../../Tools/Toast";

const ManageBmsRights = () => {
    const [checkedServers, setCheckedServers] = useState<AccountServerProps[]>([]);
    const [serversWithBmsUserRight, setServersWithBmsUserRight] = useState<ServerProps[]>([]);
    const [selectedUser, setSelectedUser] = useState<ApplicativeAccountsProps>();
    const {t} = useTranslation();
    const {login} = useParams<{ login: string }>();
    const {casClient, serversWithBackOfficeUserRight, applicativeAccounts} = useUserContext();

    useEffect(() => {
        setSelectedUser(applicativeAccounts.find((user) => user.login === login))
    }, [applicativeAccounts]);

    useEffect(() => {
        if (selectedUser && serversWithBackOfficeUserRight.length > 0) {
            const serversWithBmsUserRight = selectedUser?.applicativeRights?.accountServersWithBmsAccess?.map((server: {
                serverId?: string
            }) => {
                return serversWithBackOfficeUserRight.find(serverInArray => serverInArray.uuid === server.serverId)
            });
            setServersWithBmsUserRight(serversWithBmsUserRight as ServerProps[]);
        }
    }, [selectedUser, serversWithBackOfficeUserRight]);

    const handleSelect = (e: CheckboxChangeEvent) => {
        let _checkedServers = [...checkedServers];
        if (e.checked) {
            _checkedServers.push(e.value);
        } else {
            _checkedServers = _checkedServers.filter((server) => server.uuid !== e.value.uuid);
        }
        setCheckedServers(_checkedServers)
    }

    function handleUpdateBmsRights() {
        const dataToUpdate = {
            login: selectedUser?.login,
            applicativeRights: {
                accountServersWithBmsAccess: serversWithBmsUserRight.filter((server) => !checkedServers.some((_server) => _server.uuid === server.uuid)).map((server) => {
                    return {
                        serverId: server.uuid,
                    }
                })
            }
        }
        const serverCode = serversWithBackOfficeUserRight.find((_server: { uuid: string }) => {
            const accountServersEntries = dataToUpdate?.applicativeRights?.accountServersWithBmsAccess?.entries();
            const nextEntry = accountServersEntries?.next();
            const serverId = nextEntry?.value?.[1]?.serverId;
            return _server.uuid === serverId;
        });

        updateApplicativeRightsForSelectedAccount(dataToUpdate, casClient.getToken(), (serverCode != undefined ? serverCode.code : ""), true).then(() => {
            toastSuccessUpdatingAccount(t('general.Toasts.successUpdate'))
            setServersWithBmsUserRight(serversWithBmsUserRight.filter((server) => !checkedServers.some((_server) => _server.uuid === server.uuid)));
        });
    }

    return (
        <>
            {
                (serversWithBmsUserRight && serversWithBmsUserRight.length > 0) &&
                <BmsManagementContainer>
                    <h2>{t('general.AccountManagement.titleBms')}</h2>
                    <h3>{selectedUser?.login} {t('general.AccountManagement.subtitleBms')}</h3>
                    <div className="list-servers">
                        {serversWithBmsUserRight.map((server) => {
                            if (!server) {
                                return <div key='1'></div>;
                            }
                            return (
                                <div key={server.uuid} className="server">
                                    <Checkbox data-testid={server.uuid} id={server.uuid} value={server}
                                              name={'server'}
                                              onChange={handleSelect}
                                              checked={checkedServers.some((_server) => _server.uuid === server.uuid)}/>
                                    <label id={server.code} className="ml-2">
                                        {server.code}
                                    </label>
                                </div>
                            );
                        })}
                    </div>
                    <div>
                        <Button onClick={handleUpdateBmsRights}
                                label={t('general.Button.delete')} className="delete-button"/>
                    </div>
                </BmsManagementContainer>
            }
        </>
    )
}

export default ManageBmsRights;
