export const APP_PATH = {
    ROOT: '/',
    CALLBACK: '/callback',
    AUTH: '/auth',
    LOGIN: '/login',
    LOGOUT: '/logout',
    APPLICATIVE_SERVERS_MANAGEMENT: '/applicative-servers-management/:serverId',
    APPLICATIVE_ACCOUNTS_MANAGEMENT: '/applicative-accounts-management/:login',
    BACKOFFICE_ACCOUNTS: '/backoffice-accounts',
};

export const AUTH_PATH = '/#/auth';
export const LOGIN = 'dydu-login'; // shared with channels (onPrem context)