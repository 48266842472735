import './index.css';
import "../src/Services/i18n"

import App from './App';
import React from 'react';
import {createRoot} from 'react-dom/client';
import {HashRouter} from "react-router-dom";
import {ThemeProvider} from "styled-components";
import Theme from "./Assets/Theme/Theme";
import UserProvider from "./Contexts/UserContext";

const container: Element = document.getElementById('root') as Element;
const root = createRoot(container);
root.render(
    <HashRouter>
        <UserProvider>
            <ThemeProvider theme={Theme}>
                <App/>
            </ThemeProvider>
        </UserProvider>
    </HashRouter>
);

