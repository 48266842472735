import DataTable from "../DataTable/DataTable";
import {ChangeEvent, useState} from "react";
import Input from "../Input/Input";
import {DataListContainer} from "./Style";
import {ColumnProps} from "../DataTable/DataTable";
import {DataTableValue} from "primereact/datatable";
import {useTranslation} from "react-i18next";


interface DataListProps {
    data: DataTableValue[];
    columns: ColumnProps[]
    renderHeader?: (() => JSX.Element) | undefined | boolean;
}

const DataList = ({data, columns, renderHeader}: DataListProps) => {
    const [searchTerm, setSearchTerm] = useState<string>("");
    const {t} = useTranslation();


    const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(e.target.value.toLowerCase());
    };

    const filteredData = data?.filter((data: unknown) => {
        return data && Object.values(data).some(
            (value) =>
                typeof value === "string" && value.toLowerCase().includes(searchTerm)
        );
    });

    return (
        <DataListContainer>
            <Input className="input-search" placeholder={t('general.Input.search')} onChange={handleSearch}
                   icon="pi pi-search" dataTestId={"search"}/>
            <div>
                <DataTable columns={columns} data={filteredData} renderHeader={renderHeader}
                           paginator={true}
                />
            </div>
        </DataListContainer>
    )
}

export default DataList;