import {Button as PrimeButton} from './Style';
import {ButtonProps as PrimeButtonProps} from 'primereact/button';

export interface ButtonProps extends PrimeButtonProps {
    label?: string;
    title?: string;
    type?: 'button' | 'submit' | 'reset';
    icon?: string;
    className?: string;
    hidden?: boolean;
    iconPos?: 'top' | 'bottom' | 'left' | 'right';
    onClick: () => void
    disabled?: boolean;
    loading?: boolean;
}

const Button = ({
                    label,
                    onClick,
                    loading,
                    disabled,
                    icon,
                    title,
                    className,
                    iconPos,
                    type = 'button',
                }: ButtonProps) => {
    const props = {
        type,
        label,
        onClick,
        disabled,
        loading,
        icon,
        title,
        className,
        iconPos,
    };
    return <PrimeButton {...props} />;
};

export default Button;
