import styled from "styled-components";


export const AccountsRightsManagementContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    min-width: 200px;
    margin-top: 30px;


    .accountsListContainer {
        ul {
            list-style: none
        }
    }

    .button-back {
        margin-top: 30px;
    }

    .rights-management {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-around;
        margin-top: 50px;
        width: 100%;
    }

    .delete-button {
        margin-top: 20px;
    }


`
export const BmsManagementContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 400px;
    flex: 1;
    border-radius: 10px;
    background-color: ${({theme}) => theme.color_grey__200};
    box-shadow: rgba(80, 96, 112, 0.3) 0px 2px 4px;


    .usersListContainer {
        margin-top: 20px;
    }

    .search-container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: 80%;
        margin-top: 20px
    }

    .userlist-container {
        display: flex;
        flex-direction: column;
        align-items: self-end;
        justify-content: center;
        width: 100%;
        margin-right: 20px
    }

    .p-checkbox {
        margin-right: 10px;
    }

    .button-delete {
        margin-top: 30px;
        margin-bottom: 10px;
    }

    .account {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        width: 100%;
    }

    .accounts-list {
        display: flex;
        flex-direction: column;

        ul {
            list-style: none
        }

    }

    .accountsListContainer {
        overflow-y: auto !important;
        max-height: 300px;
        margin-top: 30px;
        width: 100%;
    }
`

export const BoManagerContainer = styled(BmsManagementContainer)`

    .add-account-rights {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: 100%;
    }

;
`

